<template>
  <div>
    <div v-if="!pageLoading">
      <div class="tw-mb-4 c-blue-text">
        <v-chip-group mandatory active-class="primary">
          <v-chip active-class="false" :to="{ name: 'Dashboard' }">Dashboard</v-chip>
          <span class="tw-mx-2 tw-mt-2"> > </span>
          <v-chip :to="{ name: 'Products' }">Products </v-chip>
          <span class="tw-mx-2 tw-mt-2"> > </span>
          <v-chip :to="{ name: 'AgentGuide' }">{{ $options.name }}</v-chip>
        </v-chip-group>
      </div>

      <!-- Top Card -->
      <top-card current="Beneficiaries" cover="Elimu Plan" :amount="5"> Kindly provide some additional details before
        you proceed </top-card>

      <!-- Beneficiary Form Card -->
      <v-card elevation="2" class="tw-px-2 md:tw-px-6 tw-mt-4 md:tw-mt-10">
        <card-title class="tw-mt-3" icon="/images/icons/elimu-b.png">Add Beneficiary</card-title>
        <back-nav to="PersonalDetails" />

        <!-- Beneficiary Form and Details -->
        <beneficiary-details v-if="remainingPercentage > 0" :remaining-percentage="remainingPercentage" />

        <!-- Beneficiaries Table -->
        <div v-if="beneficiaries.length > 0">
          <v-data-table :headers="headers" :items="beneficiaries" :items-per-page="5"
            class="elevation-1 mt-12 tw-rounded-lg">
            <!-- Edit Beneficiary Dialog -->
            <template v-slot:top>
              <v-dialog v-model="dialog" max-width="600px">
                <v-card class="tw-rounded-lg tw-shadow-lg tw-bg-gray-50 tw-p-4">
                  <v-form ref="form" v-model="valid">
                    <!-- Header Section -->
                    <div class="tw-flex tw-justify-between tw-items-center tw-mb-4">
                      <card-title icon="/images/icons/elimu-b.png" class="tw-text-lg tw-font-semibold tw-text-blue-600">
                        Edit Beneficiary Details
                      </card-title>
                      <v-btn icon @click="dialog = false">
                        <v-icon color="blue">mdi-close</v-icon>
                      </v-btn>
                    </div>

                    <!-- Beneficiary Edit Form -->
                    <v-container fluid>
                      <!-- Row for Full Name and Date of Birth -->
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-text-field label="Full Names" v-model="editedItem.quoBenName"
                            :rules="[required('Full name')]" outlined dense
                            class="tw-bg-white tw-rounded tw-shadow-sm"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-menu v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y>
                            <template #activator="{ on, attrs }">
                              <v-text-field v-model="editedItem.quoBenDateOfBirth" label="Date Of Birth" v-bind="attrs"
                                v-on="on" outlined  readonly dense class="tw-bg-white tw-rounded tw-shadow-sm"></v-text-field>
                            </template>
                            <v-date-picker v-model="editedItem.quoBenDateOfBirth" no-title @input="menu = false"
                              :max="givenYearsAgo(0)" :min="givenYearsAgo(100)"></v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>

                      <!-- Row for Gender and Percentage -->
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-radio-group v-model="editedItem.gender" :rules="[required('Gender')]" row dense
                            class="tw-flex tw-items-center">
                            <v-radio label="Male" value="male"></v-radio>
                            <v-radio label="Female" value="female"></v-radio>
                          </v-radio-group>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field type="number" label="Percentage" v-model="editedItem.quoBenPerc"
                            :rules="[required('Percentage'), numberFormat()]" outlined dense
                            class="tw-bg-white tw-rounded tw-shadow-sm"></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>

                    <!-- Action Buttons -->
                    <v-card-actions class="tw-flex tw-flex-col tw-items-center tw-justify-between tw-mt-4">
                      <v-btn color="primary" @click="updateBeneficiary" :disabled="!valid"
                        class="tw-w-full tw-my-2 tw-font-semibold tw-rounded-lg tw-shadow-md">
                        <v-icon left>mdi-content-save</v-icon> Save
                      </v-btn>
                      <v-btn text @click="dialog = false" class="tw-w-full tw-mt-2 tw-text-gray-600 tw-font-semibold">
                        <v-icon left>mdi-close-circle-outline</v-icon> Cancel
                      </v-btn>
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-dialog>
            </template>

            <template v-slot:[`item.quoBenPerc`]="{ item }">
              {{ item.quoBenPerc }} %
            </template>
            <template v-slot:[`item.quoBenGuardianName`]="{ item }">
              <v-chip v-if="item.quoBenGuardianName !== 'string'" class="primary" small>{{ item.quoBenGuardianName
                }}</v-chip>
              <v-chip v-else class="accent primary--text" small>Adult</v-chip>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <div class="tw-flex">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon small class="mr-3" color="secondary" @click="editItem(item)" v-bind="attrs"
                      v-on="on">mdi-pencil</v-icon>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon small color="error" @click="deleteItem(item)" v-bind="attrs" v-on="on">mdi-delete</v-icon>
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </div>
            </template>
          </v-data-table>

          <!-- Percentage Total Alert -->
          <div class="tw-flex tw-justify-between my-6">
            <v-chip v-if="totalPercentage < 100" class="tw-bg-gradient-to-r from-blue-500 to-blue-400 tw-text-white">
              <div class="tw-text-red-600">
                <v-icon left small color="red">mdi-alert</v-icon> Beneficiary allocation should add up to 100%
              </div>

            </v-chip>
            <div class="tw-text-blue-600 tw-font-semibold">Total: {{ totalPercentage }} %</div>
          </div>
        </div>

        <!-- No Beneficiaries Message -->
        <div v-else class="tw-flex tw-flex-col tw-items-center tw-py-10">
          <img src="/images/icons/beneficiaries.png" alt="No Beneficiaries" class="tw-w-24 tw-mb-4" />
          <p class="tw-text-center tw-text-blue-600 tw-font-medium">
            No beneficiaries added. Click <span class="tw-font-semibold">"Add Beneficiary"</span> to get started.
          </p>
        </div>

        <!-- Continue Button -->
        <div class="tw-flex tw-justify-center tw-mt-10">
          <v-btn class="secondary tw-w-64" :disabled="remainingPercentage > 0" large @click="proceed"
            :loading="loading">Continue</v-btn>
        </div>
      </v-card>
    </div>

    <!-- Loading Indicator -->
    <div v-else class="tw-w-full tw-flex tw-justify-center tw-items-center tw-py-12">
      <v-progress-circular size="50" color="primary" indeterminate></v-progress-circular>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CardTitle from '@/components/CardTitle';
import TopCard from '@/components/buy/TopCard';
import BeneficiaryDetails from '@/components/buy/BeneficiaryDetails';
import BackNav from '../BackNav';

import validations from '@/utils/validations';
import { age, formatDate, givenYearsAgo } from '@/utils/time';
import { beneficiaryList } from '@/utils/beneficiaries';

export default {
  name: 'Beneficiaries',
  metaInfo: {
    title: 'Buy Cover - Beneficiaries',
  },
  components: {
    CardTitle,
    TopCard,
    BeneficiaryDetails,
    BackNav,
  },
  data() {
    return {
      date: '',
      beneficiariesList: beneficiaryList(),
      pageLoading: false,
      valid: false,
      dialog: false,
      menu: false,
      beneficiaryStatus: true,
      ...validations,
      editedIndex: -1,
      initialEditedItemPercentage: 0,
      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'quoBenName',
        },
        { text: 'DOB', value: 'quoBenDateOfBirth' },
        { text: 'ID No', value: 'quoBenIdNo' },
        // { text: 'Gender', value: 'gender' },
        { text: 'Phone', value: 'quoBenTel' },
        { text: 'Percentage', value: 'quoBenPerc' },
        { text: 'Relationship', value: 'quoBenRelType' },
        { text: 'Guardian', value: 'quoBenGuardianName' },
        { text: 'Action', value: 'action' },
      ],
      editedItem: {
        guardian: {},
        dateOfBirth: '',
      },
      defaultItem: {
        guardian: {},
        dateOfBirth: '',
      },
      loading: false,
    };
  },
  computed: {
    ...mapGetters('quote', ['beneficiaries']),

    totalPercentage() {
      return this.beneficiaries.reduce(
        // eslint-disable-next-line radix
        (result, item) => result + parseInt(item.quoBenPerc),
        0,
      );
    },

    remainingPercentage() {
      return 100 - this.totalPercentage;
    },

    getAge() {
      if (this.editedItem && this.editedItem.quoBenDateOfBirth) {
        return age(this.editedItem.quoBenDateOfBirth);
      }
      return 19;
    },
  },
  created() {
    if (typeof window !== 'undefined' && window.gtag) {
      window.gtag('event', 'add_beneficiary_page_visited_by_agent', {
        event_category: 'add beneficiary page component created',
        event_label: 'add beneficiary page component created',
      });
    }
  },

  methods: {
    formatDate,
    proceed() {
      this.loading = true;
      const benData = this.beneficiaries;
      this.$store.dispatch('quote/saveBeneficiaries', { benData });
      this.$emit('to-health-questionnaire');
      this.loading = false;
    },

    editItem(item) {
      this.editedIndex = this.beneficiaries.indexOf(item);
      this.initialEditedItemPercentage = item.quoBenPerc;
      this.editedItem = {
        ...{},
        ...item,
      };
      this.dialog = true;
    },

    deleteItem(item) {
      const index = this.beneficiaries.indexOf(item);
      // eslint-disable-next-line no-restricted-globals,no-alert
      confirm('Are you sure you want to delete this item?');
      this.beneficiaries.splice(index, 1);
      this.$store.commit('quote/updateBeneficiaries', this.beneficiaries);
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },

    updateBeneficiary() {
      if (this.editedIndex > -1) {
        this.beneficiaries.splice(this.editedIndex, 1, this.editedItem);
        this.$store.commit('quote/updateBeneficiaries', this.beneficiaries);
      }
      this.close();
    },
    givenYearsAgo,
  },
};
</script>
<style scoped>
.bg-gradient-blue {
  background: #7b91b0;
}
</style>
