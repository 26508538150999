<template>
  <div>
    <div v-if="!pageLoading">
      <!-- Navigation Chips -->
      <div class="tw-mb-4 c-blue-text tw-flex tw-flex-wrap items-center">
        <v-chip-group mandatory active-class="primary">
          <v-chip active-class="false" :to="{ name: 'Dashboard' }">Dashboard</v-chip>
          <span class="mt-2 mx-2"> > </span>
          <v-chip :to="{ name: 'Products' }">Products</v-chip>
          <span class="mt-2 mx-2"> > </span>
          <v-chip :to="{ name: 'AgentGuide' }">{{ $options.name }}</v-chip>
        </v-chip-group>
      </div>

      <!-- Top Card -->
      <top-card current="PersonalDetails" cover="Elimu Plan" :amount="5">
        Kindly provide some additional details before you proceed
      </top-card>

      <!-- Main Form Card -->
      <v-card elevation="2" class="tw-px-2 md:tw-px-6 tw-mt-1 md:tw-mt-10 tw-rounded-lg tw-shadow-md">
        <card-title icon="/images/icons/elimu-b.png">Personal Details.......</card-title>
        <back-nav to="PersonalDetails" />

        <v-form v-model="isFormValid" class="tw-px-4 md:tw-px-12 tw-mt-4 md:tw-mt-10">
          <!-- Information Header -->
          <div class="tw-w-full text-center">
            <h3 class="tw-text-base md:tw-text-lg tw-mt-3 md:tw-mt-10 pb-6 c-blue-text font-semibold">
              Please Provide the information of <span class="c-blue-text">Policy Owner</span> before proceeding..
            </h3>
          </div>

          <!-- Form Fields Grid -->
          <div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-4">
            <!-- Form Inputs -->
            <v-select class="md:tw-mx-5" :items="title" label="Title"></v-select>
            <v-text-field v-model="ownerForm.surname" class="my-2 tw-px-0 md:tw-px-6" label="Surname" type="text"
              :rules="[required('Surname')]"></v-text-field>
            <v-text-field v-model="ownerForm.othernames" class="my-2 tw-px-0 md:tw-px-6" label="Other Names" type="text"
              :rules="[required('Other Names')]"></v-text-field>

            <!-- Date Picker -->
            <v-menu v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y
              max-width="290px" min-width="290px">
              <template #activator="{ on, attrs }">
                <v-text-field v-model="ownerForm.dateOfBirth" persistent-hint readonly label="Date Of Birth"
                  v-bind="attrs" v-on="on" class="md:tw-mx-5" :rules="[required('Date of Birth')]"></v-text-field>
              </template>
              <v-date-picker :active-picker.sync="activePicker" v-model="ownerForm.dateOfBirth" no-title
                @input="menu = false" :max="givenYearsAgo(18)" :min="givenYearsAgo(65)" color="primary"></v-date-picker>
            </v-menu>

            <!-- Additional Fields -->
            <v-select v-model="ownerForm.nationality" class="md:tw-mx-5" :items="nationalities" label="Nationality"
              :rules="[required('Nationality')]"></v-select>
            <vue-tel-input-vuetify v-bind="phoneNumberBindProps" v-model="phoneNumber" class="my-2 tw-px-0 md:tw-px-6"
              :rules="[required('Mobile No.')]" label="Mobile No."></vue-tel-input-vuetify>
            <v-text-field v-model="ownerForm.email" class="my-2 tw-px-0 md:tw-px-6" label="Email" type="text"
              :rules="[required('Email'), emailFormat()]"></v-text-field>
            <v-select v-model="ownerForm.maritalStatus" class="md:tw-mx-5" :items="maritalStatusList"
              label="Marital Status" :rules="[required('Marital Status')]"></v-select>

            <!-- Gender Selection -->
            <div class="grid grid-cols-3 my-6 tw-px-0 md:tw-px-6">
              <h4 class="c-blue-text">Gender</h4>
              <v-radio-group v-model="ownerForm.gender" class="my-2 tw-px-0 md:tw-px-6" :rules="[required('Gender')]"
                row>
                <v-radio label="Male" value="MALE" class="tw-mt-0 md:tw-mt-5 tw-pr-10"></v-radio>
                <v-radio label="Female" value="FEMALE" class="tw-mt-0 md:tw-mt-5"></v-radio>
              </v-radio-group>
            </div>

            <!-- Conditional Fields for High Quote Sum -->
            <div v-if="quoteSumState >= 1000000" class="tw-w-full text-center tw-py-4">
              <h3 class="c-blue-text tw-text-lg font-semibold">Please select source of funds</h3>
              <div class="tw-mt-2 tw-ml-8">
                <v-checkbox id="check" label="Source of Funds" v-model="isChecked" :rules="[check()]"></v-checkbox>
              </div>
            </div>
          </div>

          <!-- Continue Button -->
          <div class="container tw-w-full tw-py-3 md:tw-py-6 tw-text-center">
            <v-btn id="ownerSubmit" class="secondary tw-w-64 tw-my-8 tw-mx-auto" large @click="policyOwnerSubmission"
              :disabled="!isFormValid" :loading="loading">Continue</v-btn>
          </div>
        </v-form>
      </v-card>

      <!-- Source of Funds Dialog -->
      <v-dialog v-model="isSource" persistent>
        <v-card elevation="2" class="tw-px-2 md:tw-px-6 tw-mt-5 md:tw-mt-10 mx-auto tw-rounded-lg tw-shadow-md"
          max-width="600px">
          <card-title :icon="scanObject(currentProduct, 'icon', '/images/icons/elimu.png')">Source of Funds</card-title>
          <div class="tw-w-full tw-text-center">
            <h2 class="tw-font-semibold pt-7 pb-5">{{ scanObject(currentProduct, 'title') }}</h2>
            <p class="pb-5">Please confirm the source of funds for this investment</p>
            <v-form class="pt-5" ref="form" v-model="isValid">
              <v-select id="sourceOfFunds" v-model="sourceOfFunds" class="md:tw-mx-5" :items="sourceOfFundsList"
                label="Source Of Funds" :rules="[required('Source Of Funds')]"></v-select>
            </v-form>
          </div>
          <div class="container tw-w-full md:tw-py-6 tw-text-center">
            <v-btn id="confirmSource" class="secondary tw-w-64 tw-my-8 tw-mx-auto" @click="confirmSource" large
              :disabled="!isValid">Confirm</v-btn>
          </div>
        </v-card>
      </v-dialog>
    </div>

    <!-- Loading Spinner -->
    <div v-else class="tw-w-full tw-flex tw-justify-center tw-items-center">
      <v-progress-circular size="50" color="primary" indeterminate></v-progress-circular>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CardTitle from '@/components/CardTitle';
import TopCard from '@/components/buy/TopCard';
import BackNav from '../BackNav';

import validations from '@/utils/validations';
import { formatDate, givenYearsAgo } from '@/utils/time';
import { nationalityList } from '@/utils/nationalities';

export default {
  name: 'OwnerDetails',
  metaInfo: {
    title: 'Buy Cover - Owners Details',
  },
  components: {
    CardTitle,
    TopCard,
    BackNav,
  },

  data() {
    return {
      writeOccupation: null,
      otherOccupation: false,
      sourceOfFunds: null,
      isChecked: false,
      clientIdentity: '',
      date: '',
      title: ['Mr', 'Mrs', 'Miss', 'Prof', 'Doc'],
      maritalStatusList: ['Single', 'Married', 'Divorced', 'Widowed'],
      occupation: null,
      occupationList: [
        'ACCOUNTANT', 'ADMINISTRATOR', 'BANKER', 'BUSINESS PERSON', 'CIVIL SERVANT', 'DRIVER', 'ENGINEER', 'FARMER', 'HEALTH/MEDICAL PRACTITIONER',
        'INSURANCE OFFICER', 'LAWYER', 'MECHANIC', 'PILOT', 'POLICE OFFICER', 'SALES PERSON/MARKETER',
        'SECURITY OFFICER', 'SOLDIER', 'STUDENT', 'TEACHER/LECTURER', 'TECHNICIAN', 'Other',
      ],
      activateOccupation: false,
      pageLoading: false,
      isSource: false,
      isValid: false,
      menu: false,
      ownerForm: {
        surname: '',
        othernames: '',
        email: '',
        dateOfBirth: '',
        nationality: 'Kenyan',
        otherOccupation: '',
      },
      ...validations,
      isFormValid: false,
      activePicker: null,
      loading: false,
      phoneNumber: null,
      nationalities: nationalityList(),
      sourceOfFundsList: [
        'Investment Income',
        'Gift or Inheritance',
        'Loan',
        'Sale of Investment Porfolio/Property',
        'Pension',
        'Salary/Savings',
      ],
    };
  },
  watch: {
    isChecked() {
      if (this.isChecked === true) {
        this.isSource = true;
      } else {
        this.isSource = false;
      }
    },
    date() {
      this.form.coverFrom = this.formatDate(this.date);
    },
    phoneNumber() {
      this.ownerForm.mobileNumber = this.phoneNumber.replace(/\s/g, '');
    },
    menu() {
      // eslint-disable-next-line no-return-assign
      setTimeout(() => (this.activePicker = 'YEAR'));
    },
    occupation() {
      this.ownerForm.otherOccupation = this.occupation;
      if (this.occupation && this.occupation === 'Other') {
        this.activateOccupation = true;
      } else {
        this.activateOccupation = false;
      }
    },
    writeOccupation() {
      this.ownerForm.otherOccupation = this.writeOccupation;
    },
  },
  computed: {
    computedDateFormatted() {
      return this.form.coverFrom || this.formatDate(this.date);
    },
    ...mapState({
      quoteSumState: (state) => state.userPersonalData.personalData.amount,
    }),
  },
  created() {
    if (typeof window !== 'undefined' && window.gtag) {
      window.gtag('event', 'capture_owner_dtls_page_visited_by_agent', {
        event_category: 'capture owner details component created',
        event_label: 'capture owner details component created',
      });
    }
  },
  methods: {
    policyOwnerSubmission() {
      this.loading = true;
      this.$store.commit('quote/policyOwnerInfo', this.ownerForm);
      this.$emit('to-beneficiaries');

      this.loading = false;
    },
    confirmSource() {
      if (this.sourceOfFunds) {
        this.$store.commit('quote/sourceOfFundsInfo', this.sourceOfFunds);
        this.isSource = false;
      } else {
        this.isSource = true;
      }
    },
    formatDate,
    givenYearsAgo,
  },
};
</script>

<style scoped>

.v-card {
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.v-dialog .v-card {
  border-radius: 10px;
  padding: 16px;
}

.c-title {
  font-weight: 600;
  color: #1a237e;
}

.tw-ml-8 {
  margin-left: 2rem !important;
}

.tw-text-center {
  text-align: center;
}

.tw-rounded-lg {
  border-radius: 8px;
}
</style>
