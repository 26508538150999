<template>
  <div>
    <v-dialog v-model="existingPolicyDialog" max-width="1000px">
      <v-card>
        <v-form ref="form" v-model="valid">
          <div class="tw-flex tw-flex-row tw-justify-between">
            <card-title class="my-7 ml-5" icon="/images/icons/elimu-b.png"
              >Other Existing Policies
            </card-title>
            <v-btn text class="tw-mt-5" @click="close">
              <v-icon class="primary--text">mdi-close</v-icon>
            </v-btn>
          </div>

          <v-container>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th scope="col" class="text-left">ID</th>
                    <th scope="col" class="text-left">Plan Type</th>
                    <th scope="col" class="text-left">Policy Number</th>
                    <th scope="col" class="text-left">Premiun Amount</th>
                    <th scope="col" class="text-left">Sum Assured</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, i) in desserts" :key="i">
                    <td>{{ i + 1 }}</td>
                    <td>{{ item.planType }}</td>
                    <td>{{ item.policyNumber }}</td>
                    <td>{{ item.premiunAmount }}</td>
                    <td>{{ item.sumAssured }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-divider></v-divider>

            <div class="mt-10 mb-6 mx-7 tw-flex tw-flex-row tw-justify-between">
              <h2 class="tw-text-xl tw-font-semibold primary--text">
                Total Cumulative sum assured
              </h2>
              <h2 class="pr-5 tw-text-xl primary--text">Ksh 8,000,000</h2>
            </div>
          </v-container>
          <v-divider></v-divider>

          <v-card-actions>
            <div class="ma-auto pb-5">
              <v-btn
                id="inviteUserBtn"
                class="ma-5 pa-4"
                color="secondary"
                @click="proceed"
              >
                Proceed
                <v-icon dark right> mdi-checkbox-marked-circle </v-icon>
              </v-btn>
            </div>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import validation from '@/utils/validations';
import CardTitle from '@/components/CardTitle';

export default {
  name: 'ExistingPolicies',
  components: {
    CardTitle,
  },
  props: ['existingPolicyDialog'],

  data: () => ({
    menu: false,
    valid: false,
    dialog: false,
    dialogInvite: false,
    alert: false,
    desserts: [
      {
        planType: 'Imarika',
        policyNumber: 'PN/AKH/0002/2022',
        premiunAmount: 'Ksh 50,000',
        sumAssured: 'Ksh 7,000,000',
      },
      {
        planType: 'AKiba Halisi',
        policyNumber: 'PN/AKH/0002/2020',
        premiunAmount: 'Ksh 20,000',
        sumAssured: 'Ksh 1,800,000',
      },
      {
        planType: 'Elimu',
        policyNumber: 'PN/AKH/0002/2018',
        premiunAmount: 'Ksh 18,000',
        sumAssured: 'Ksh 4,000,000',
      },
    ],

    ...validation,
  }),
  methods: {
    proceed() {
      this.$emit('activate-lien-form');
    },
    close() {
      this.$emit('close-dialog-form');
    },
  },
};
</script>
