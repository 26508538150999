<template>
  <div>
    <div v-if="!pageLoading">
      <!-- Breadcrumbs -->
      <div class="tw-mb-4 c-blue-text">
        <v-chip-group mandatory active-class="primary">
          <v-chip active-class="false" :to="{ name: 'Dashboard' }">Dashboard</v-chip>
          <span class="tw-mx-2 mt-2"> > </span>
          <v-chip active-class="false" :to="{ name: 'Products' }">Products</v-chip>
          <span class="tw-mx-2 mt-2"> > </span>
          <v-chip :to="{ name: 'AgentGuide' }">{{ $options.name }}</v-chip>
        </v-chip-group>
      </div>

      <!-- Main Card -->
      <v-card elevation="2"
        class="main-card tw-px-4 md:tw-px-8 tw-py-6 md:tw-py-8 tw-rounded-lg tw-shadow-lg tw-bg-white">
        <!-- Title and Back Navigation -->
        <div class="tw-flex tw-items-center tw-justify-between tw-mb-6">
          <div class="tw-flex tw-items-center">
            <img src="/images/icons/elimu-b.png" alt="Icon" class="tw-w-10 tw-h-10 tw-mr-3" />
            <h2 class="tw-text-2xl tw-font-semibold c-blue-text">Declaration Form</h2>
          </div>
          <!-- BackNav Component -->
          <back-nav to="ProposalSummary" />
        </div>

        <!-- Form Content -->
        <v-form ref="form" v-model="isValid">
          <div
            class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-6 tw-bg-gray-50 tw-p-6 tw-rounded-lg tw-shadow-inner">
            <!-- Authorization Section -->
            <v-card class="accent tw-rounded-lg tw-shadow-md tw-p-4">
              <h3 class="tw-font-semibold tw-text-lg tw-text-gray-700">Authorization to Obtain Information</h3>
              <v-card-text>
                <p class="tw-text-gray-600 tw-leading-relaxed tw-mt-3">
                  Personal data refers to all information that may directly or indirectly identify you. In order to
                  provide
                  you with products and services, we need to collect, use, share, and store your personal data. This may
                  include information provided by you or obtained from third parties. The information may be used to
                  assist
                  us in providing the service you are applying for and shall be used in fulfillment of contractual
                  obligations. We may also use the information to advise you of other products and services provided by
                  us,
                  to confirm, update and enhance records, and to establish your identity. The data collected may be
                  shared,
                  transferred, stored, or processed within or outside the Kenyan jurisdiction. Any reference to "We" or
                  "Us"
                  will mean Apollo Group. Refer to our website www.apainsurance.org to see the entities under Apollo
                  Group.
                </p>
              </v-card-text>
              <v-checkbox v-model="checkbox" required :rules="[check()]" class="pl-4 primary--text">
                <template v-slot:label>
                  <div class="tw-font-semibold primary--text">
                    I authorize APA Life Assurance to obtain and use my personal information as per the above
                  </div>
                </template>
              </v-checkbox>
              <p class="pl-12 tw-text-xs tw-text-gray-500">
                Note: In case you would like to revoke the consent kindly send an email to privacy@apollo.co.ke
              </p>
            </v-card>

            <!-- Declaration Section -->
            <v-card class="accent tw-rounded-lg tw-shadow-md tw-p-4">
              <h3 class="tw-font-semibold tw-text-lg tw-text-gray-700">Declaration Form</h3>
              <v-card-text>
                <ol class="tw-list-decimal tw-ml-5 tw-mt-3 tw-text-gray-600 tw-leading-relaxed">
                  <li class="py-2">
                    I/We understand that the statements and all number information provided in this application form,
                    whether in my/our own handwriting or not, are complete and true to the best of my/our knowledge and
                    that it will form part of the policy.
                  </li>
                  <li class="py-2">
                    Change in amount, classification, or benefits shall be effective unless agreed to in writing by the
                    policy owner.
                  </li>
                  <li class="py-2">
                    It is also agreed that APA Life will incur no liability under this application until the application
                    has been received and approved and the premium has been paid and accepted by APA Life.
                  </li>
                  <li class="py-2">
                    I/We understand that no intermediary has the authority to waive the answers to any of the questions
                    in
                    this application or to make or alter any contract for APA Life Assurance.
                  </li>
                  <li class="py-2">
                    I/We declare that I/We am/are in good health and free from disease or disability or any other
                    symptoms
                    thereof, unless otherwise stated above under Section 4 of this form.
                  </li>
                  <li class="py-2">
                    I/We consent to receive the policy document via the E-Mail address provided in Section 1 above and
                    I/We also understand that the policy document will be considered delivered once dispatched to the
                    email address.
                  </li>
                </ol>
              </v-card-text>
            </v-card>
          </div>

          <!-- Terms and Conditions Checkbox -->
          <v-checkbox v-model="checkbox2" :rules="[check()]" class="pl-4 primary--text tw-mt-6">
            <template v-slot:label>
              <div class="tw-font-semibold primary--text">
                I confirm that I’ve read and understood the Terms and conditions of the product. Verify sign-off with
                OTP
                code sent to your client's email or mobile.
              </div>
            </template>
          </v-checkbox>

          <!-- Proceed Button -->
          <div class="tw-flex tw-flex-wrap tw-justify-center tw-mt-6">
            <v-btn class="secondary tw-w-64 tw-my-8" large @click="proceedToOTP" :disabled="!isValid">
              Proceed to Payment
            </v-btn>
          </div>
        </v-form>
      </v-card>
    </div>

    <!-- Loading Spinner -->
    <div v-else class="tw-w-full tw-flex tw-justify-center tw-mt-10">
      <v-progress-circular size="50" color="primary" indeterminate></v-progress-circular>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
// import CardTitle from '@/components/CardTitle';
import BackNav from '../BackNav';
import validations from '@/utils/validations';

export default {
  name: 'DeclarationForm',
  metaInfo: {
    title: 'Buy Cover - Declaration Form',
  },
  components: {
    // CardTitle,
    BackNav,
  },
  data() {
    return {
      pageLoading: false,
      isValid: false,
      ...validations,
      form: {
        optionSelected: '',
      },
      checkbox: null,
      checkbox2: null,
      menu: false,
    };
  },

  computed: {
    ...mapState({
      clientCodeState: (state) => state.quote.clientCode,
      quoteCodeState: (state) => state.quote.quoteCode,
      clientIdState: (state) => state.quote.clientId,
    }),
  },
  created() {
    if (typeof window !== 'undefined' && window.gtag) {
      window.gtag('event', 'declaration_page_visited_by_agent', {
        event_category: 'Declaration form page component created',
        event_label: 'declaration form page component created',
      });
    }
  },

  methods: {
    proceedToOTP() {
      this.pageLoading = true;
      axios
        .post(
          `/lmsagent/quotations/generateOtpRequest?QuoteCode=${this.quoteCodeState}`,
        )
        .then((res) => {
          this.pageLoading = false;
          if (res.data.responseStatus.description === 'succeeded') {
            this.$emit('to-otp-confirmation');
          } else {
            this.error = res.data.responseStatus.description;
          }
        });
    },

  },
};
</script>
