<template>
  <div>
    <v-dialog v-model="lienDialog" max-width="600px">
      <v-card>
        <div>
          <v-btn text class="tw-mt-5" @click="close">
            <v-icon class="primary--text">mdi-close</v-icon>
          </v-btn>
        </div>

        <div class="mt-5">
          <v-img
            class="mx-auto my-12 tw-w-40"
            src="/images/thankyou.png"
          ></v-img>
          <div class="tw-flex tw-flex-col tw-items-center ml-6 mt-6">
            <h2 class="tw-text-xl tw-font-semibold primary--text">
              Thanks for the feedback!
            </h2>
            <p class="py-5 tw-w-1/2 tw-text-center">
              You will be contacted on the medical check up process
            </p>
          </div>
        </div>

        <v-card-actions>
          <div class="ma-auto pb-5">
            <v-btn
              id="inviteUserBtn"
              class="ma-5 pa-4"
              color="secondary"
              @click="proceed"
            >
              Proceed
              <v-icon dark right> mdi-checkbox-marked-circle </v-icon>
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import validation from '@/utils/validations';

export default {
  name: 'LienFeedback',
  components: {},
  props: ['lienDialog'],

  data: () => ({
    menu: false,
    valid: false,
    dialog: false,
    dialogInvite: false,
    alert: false,

    ...validation,
  }),
  methods: {
    proceed() {
      this.$emit('activate-proposal-form');
      this.$emit('close-lien-dialog');
    },
    close() {
      this.$emit('close-lien-dialog');
    },
  },
};
</script>
