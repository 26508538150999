<template>
  <div>
    <!-- Button to open dialog -->
    <div class="tw-w-full tw-flex tw-justify-end">
      <v-btn class="success tw-w-48 tw-my-4" @click="dialog = !dialog">
        <v-icon class="tw-mr-2" dense>mdi-plus</v-icon> Beneficiary
      </v-btn>
    </div>

    <!-- Dialog -->
    <v-dialog v-model="dialog" max-width="800px">
      <v-card class="tw-rounded-lg tw-shadow-lg tw-p-6 tw-bg-gray-50">
        <!-- Header Section -->
        <div class="tw-flex tw-items-center tw-justify-between">
          <v-card-title class="tw-text-lg tw-font-semibold tw-text-blue-600">
            <v-icon class="tw-mr-2" color="blue">mdi-account-heart</v-icon>
            Beneficiary Details
          </v-card-title>
          <v-btn icon @click="dialog = false">
            <v-icon color="blue">mdi-close</v-icon>
          </v-btn>
        </div>

        <!-- Form Section -->
        <v-form ref="form" v-model="valid">
          <!-- Main Beneficiary Information -->
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field label="Surname" v-model="form.surname" :rules="[required('Surname')]" outlined
                  dense></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field label="Other Names" v-model="form.lastname" :rules="[required('Other Names')]" outlined
                  dense></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-menu v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y>
                  <template #activator="{ on, attrs }">
                    <v-text-field v-model="form.dateOfBirth" label="Date Of Birth" v-bind="attrs" v-on="on"
                      :rules="[required('Date of Birth')]"  readonly outlined dense></v-text-field>
                  </template>
                  <v-date-picker v-model="form.dateOfBirth" no-title @input="menu = false" :max="givenYearsAgo(0)"
                    :min="givenYearsAgo(100)"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field type="number" label="Percentage to Beneficiary" v-model="form.percentage"
                  :rules="[required('Percentage to Beneficiary'), max(form.percentage, remainingPercentage), min(form.percentage, 1), numberFormat()]"
                  outlined dense></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-select label="Gender" v-model="form.gender" :items="['Male', 'Female']" :rules="[required('Gender')]"
                  outlined dense></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field label="ID/Passport Number" v-model="form.idNumber"
                  :rules="[required('ID/Passport Number')]" outlined dense v-if="getAge >= 17"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" v-if="getAge >= 17">
                <vue-tel-input-vuetify v-bind="phoneNumberBindProps" v-model="phoneNumber" label="Mobile No." :rules="[required('Mobile No.')]"
                  outlined dense />
              </v-col>
              <v-col cols="12" sm="6">
                <v-select label="Relationship to Policy Holder" v-model="form.relationship" :items="beneficiariesList"
                  :rules="[required('Relationship')]" outlined dense></v-select>
              </v-col>
            </v-row>
          </v-container>

          <!-- Guardian Section (if minor) -->
          <v-container fluid v-if="getAge < 18">
            <v-divider></v-divider>
            <v-card-title class="primary--text tw-mt-4 tw-font-medium">
              Guardian Details (if beneficiary is a minor)
            </v-card-title>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field label="Guardian Full Name" v-model="form.guardian.fullname"
                  :rules="[required('Guardian Full Name')]" outlined dense></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field label="Guardian ID/Passport Number" v-model="form.guardian.idNumber"
                  :rules="[required('ID/Passport Number')]" outlined dense></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <vue-tel-input-vuetify v-bind="phoneNumberBindProps" v-model="form.guardian.mobileNumber" label="Guardian Mobile No."
                  :rules="[required('Mobile No.')]" outlined dense />
              </v-col>
              <v-col cols="12" sm="6">
                <v-select label="Relationship to Policy Holder" v-model="form.guardian.policyHolder"
                  :items="beneficiariesList" :rules="[required('Relationship to Policy Holder')]" outlined
                  dense></v-select>
              </v-col>
            </v-row>
          </v-container>

          <!-- Action Buttons -->
          <v-card-actions class="tw-justify-end tw-mt-4">
            <v-btn color="secondary" @click="clear" outlined class="tw-mr-2">
              <v-icon left>mdi-format-clear</v-icon>
              Clear
            </v-btn>
            <v-btn color="primary" @click="saveBeneficiary" :disabled="!valid" class="tw-ml-2">
              <v-icon left>mdi-content-save</v-icon>
              Save Beneficiary
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import validation from '@/utils/validations';
import { formatDate, givenYearsAgo, age } from '@/utils/time';
// import CardTitle from '@/components/CardTitle';
import { beneficiaryList } from '@/utils/beneficiaries';

export default {
  name: 'BeneficiaryDetails',
  components: {
  },
  props: {
    remainingPercentage: { type: Number, default: 0, required: true },
  },
  data: () => ({
    menu: false,
    date: '',
    beneficiariesList: beneficiaryList(),
    form: {
      guardian: {},
      dateOfBirth: '',
    },
    valid: false,
    dialog: false,
    dialogInvite: false,
    alert: false,
    countryCode: null,
    activePicker: null,
    ...validation,
    phoneNumber: '',
    phoneNumberBindProps: {
      defaultCountry: 'KE',
      preferredCountries: ['KE'],
    },
  }),

  watch: {
    date() {
      this.form.coverFrom = this.formatDate(this.date);
    },
    phoneNumber() {
      this.form.mobileNumber = this.phoneNumber ? this.phoneNumber.replace(/\s/g, '') : '';
    },
    menu() {
      // eslint-disable-next-line no-return-assign
      setTimeout(() => (this.activePicker = 'YEAR'));
    },
  },
  computed: {
    computedDateFormatted() {
      return this.form.coverFrom || this.formatDate(this.date);
    },
    getAge() {
      if (this.form.dateOfBirth) {
        return age(this.form.dateOfBirth);
      }
      return 19;
    },
  },

  methods: {
    saveBeneficiary() {
      this.$store.commit('quote/beneficiariesInfo', this.form);
      this.form = {
        guardian: {},
        dateOfBirth: '',
      };
      this.dialog = false;
    },
    formatDate,
    givenYearsAgo,
    clear() {
      this.$refs.form.reset();
    },
  },
};
</script>
