<template>
  <div>
    <div v-if="!pageLoading">
      <!-- Breadcrumb Navigation -->
      <div class="tw-mb-4 c-blue-text">
        <v-chip-group mandatory active-class="primary">
          <v-chip active-class="false" :to="{ name: 'Dashboard' }">Dashboard</v-chip>
          <span class="mt-2 mx-2"> > </span>
          <v-chip :to="{ name: 'Products' }">Products</v-chip>
          <span class="mt-2 mx-2"> > </span>
          <v-chip :to="{ name: 'AgentGuide' }">{{ $options.name }}</v-chip>
        </v-chip-group>
      </div>

      <!-- Top Card -->
      <top-card current="HealthQuestionnaire" cover="Elimu Plan" :amount="5">
        Kindly provide some additional details before you proceed
      </top-card>

      <!-- Main Form Card -->
      <v-card elevation="2" class="tw-px-4 md:tw-px-6 tw-mt-2 md:tw-mt-10 tw-rounded-lg tw-shadow-sm">
        <v-form ref="form" v-model="valid">
          <!-- Card Title and Back Navigation -->
           <div class="tw-mt-4">
            <card-title icon="/images/icons/elimu-b.png" class="tw-text-lg tw-font-semibold text-blue-600">
            Health Questionnaire
          </card-title>
           </div>

        <back-nav to="Beneficiaries" />

          <!-- Conditional Banner -->
          <v-banner v-if="getProductCode === ippProductCode" color="blue" icon="mdi-lightbulb" class="tw-mt-4">
            <v-banner-text class="white--text tw-text-sm">
              Kindly note this part is not required. Click continue button below to proceed.
            </v-banner-text>
          </v-banner>

          <!-- Instruction Text -->
          <v-card-text v-else class="md:tw-w-3/4 tw-text-sm tw-text-gray-600 tw-mb-4">
            Please provide complete details on the section below and/or disclose any ailments and/or conditions your
            client may have had in the past 3 years. Additional information or reports should be attached where
            required.
          </v-card-text>

          <!-- Health Questions Section -->
          <v-container fluid>
            <!-- Question 1 -->
            <v-row class="tw-py-1">
              <v-col cols="12" md="6">
                <h5 class="tw-font-medium">a) Are you in good health?</h5>
              </v-col>
              <v-col cols="12" md="6">
                <v-radio-group v-model="formAnswer.goodHealth" row dense :rules="[required('')]">
                  <v-radio label="Yes" value="Y"></v-radio>
                  <v-radio label="No" value="N"></v-radio>
                </v-radio-group>
                <v-textarea v-if="formAnswer.goodHealth === 'N'" v-model="formAnswer.goodHealthDetails" filled
                  label="Please explain" class="tw-mt-2 tw-text-sm" auto-grow></v-textarea>
              </v-col>
            </v-row>

            <!-- Question 2 -->
            <v-row class="tw-py-2">
              <v-col cols="12" md="6">
                <h5 class="tw-font-medium">b) Do you have any known medical condition?</h5>
              </v-col>
              <v-col cols="12" md="6">
                <v-radio-group v-model="formAnswer.medicalCondition" row dense :rules="[required('')]">
                  <v-radio label="Yes" value="Y"></v-radio>
                  <v-radio label="No" value="N"></v-radio>
                </v-radio-group>
                <v-textarea v-if="formAnswer.medicalCondition === 'Y'" v-model="formAnswer.medicalConditionDetails"
                  filled label="Please explain" class="tw-mt-2 tw-text-sm" auto-grow></v-textarea>
              </v-col>
            </v-row>

            <!-- Question 3 -->
            <v-row class="tw-py-2">
              <v-col cols="12" md="6">
                <h5 class="tw-font-medium">c) Are you currently taking any medication regularly?</h5>
              </v-col>
              <v-col cols="12" md="6">
                <v-radio-group v-model="formAnswer.medication" row dense :rules="[required('')]">
                  <v-radio label="Yes" value="Y"></v-radio>
                  <v-radio label="No" value="N"></v-radio>
                </v-radio-group>
                <v-textarea v-if="formAnswer.medication === 'Y'" v-model="formAnswer.medicationDetails" filled
                  label="Please explain" class="tw-mt-2 tw-text-sm" auto-grow></v-textarea>
              </v-col>
            </v-row>

            <!-- Height and Weight -->
            <v-row class="tw-py-2">
              <v-col cols="12" md="6">
                <v-text-field v-model="formAnswer.height" label="Height (cm)" type="number" outlined dense
                  :rules="[required('Height')]"></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field v-model="formAnswer.weight" label="Weight (kg)" type="number" outlined dense
                  :rules="[required('Weight')]"></v-text-field>
              </v-col>
            </v-row>

            <!-- Additional Information -->
            <v-row class="tw-py-2">
              <v-col cols="12" md="6">
                <h5 class="tw-font-medium">f) Any other additional information?</h5>
              </v-col>
              <v-col cols="12" md="6">
                <v-radio-group v-model="formAnswer.additionalInfo" row dense :rules="[required('')]">
                  <v-radio label="Yes" value="Y"></v-radio>
                  <v-radio label="No" value="N"></v-radio>
                </v-radio-group>
                <v-textarea v-if="formAnswer.additionalInfo === 'Y'" v-model="formAnswer.additionalInfoDetails" filled
                  label="Please explain" class="tw-mt-2 tw-text-sm" auto-grow></v-textarea>
              </v-col>
            </v-row>
          </v-container>

          <!-- Continue Button -->
          <div class="tw-flex tw-justify-center tw-mt-6">
            <v-btn color="primary" class="tw-w-full tw-max-w-xs tw-py-2" :disabled="!valid" @click="submitInfo">
              Continue
            </v-btn>
          </div>
        </v-form>

        <!-- Existing Policies Component -->
        <existing-policies :existingPolicyDialog="existingPolicyDialog" @activate-lien-form="activateLien"
          @close-dialog-form="closeDialog" />
      </v-card>
    </div>

    <!-- Loading State -->
    <div v-else class="tw-w-full tw-flex tw-justify-center">
      <v-progress-circular size="50" color="primary" indeterminate></v-progress-circular>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import CardTitle from '@/components/CardTitle';
import TopCard from '@/components/buy/TopCard';
import ExistingPolicies from '@/components/buy/ExistingPolicies';
import BackNav from '../BackNav';
import kycCollectionMixins from '@/mixins/kycCollectionMixins';

import validations from '@/utils/validations';

export default {
  name: 'HealthQuestionnaire',
  mixins: [kycCollectionMixins],
  metaInfo: {
    title: 'Buy Cover - Health Questionnaire',
  },
  components: {
    CardTitle,
    TopCard,
    ExistingPolicies,
    BackNav,
  },
  data() {
    return {
      date: '',
      pageLoading: false,
      healthLoading: false,
      isValid: false,
      valid: false,
      menu: false,
      dialog: false,
      existingPolicyDialog: false,
      beneficiaryStatus: false,
      formAnswer: {},
      additionalInfo: {},
      ...validations,
      healthQuestions: [
        {
          question: 'a) Are you in good health?',
          options: [
            { text: 'Yes', value: 'Yes' },
            { text: 'no', value: 'No' },
          ],
        },
        {
          question: 'b) Do you have any known medical Condition?',
          options: [
            { text: 'Yes', value: 'Yes' },
            { text: 'no', value: 'No' },
          ],
        },
        {
          question:
            'c) Are you currently taking any medication regularly or as required?',
          options: [
            { text: 'Yes', value: 'Yes' },
            { text: 'no', value: 'No' },
          ],
        },
      ],
      ippProductCode: 2013106,
    };
  },
  computed: {
    ...mapState({
      clientIdState: (state) => state.quote.clientId,
    }),
    ...mapGetters('quote', ['getProductCode']),
  },
  created() {
    if (typeof window !== 'undefined' && window.gtag) {
      window.gtag('event', 'health_questionnaire_page_visited_by_agent', {
        event_category: 'health questionnaire component created',
        event_label: 'health questionnaire component created',
      });
    }
  },
  methods: {
    activateLien() {
      this.$emit('to-lien-form');
    },
    closeDialog() {
      this.existingPolicyDialog = false;
    },
  },
};
</script>
