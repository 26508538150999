import axios from 'axios';
import { mapState } from 'vuex';

export default {
  data() {
    return {
      form: {},
      ownerForm: {},
      lienForm: {
        optionSelected: '',
      },

    };
  },
  computed: {
    ...mapState({
      clientCodeState: (state) => state.quote.clientCode,
      quoteCodeState: (state) => state.quote.quoteCode,
      quoteSourceFunds: (state) => state.quote.quoteSourceOfFunds,
      clientIdState: (state) => state.quote.clientId,
      lifeAssuredKYCState: (state) => state.quote.lifeAssuredKYC,
      lifeAssuredKYCImagesState: (state) => state.quote.lifeAssuredKYCImages,
      policyOwnerKYCState: (state) => state.quote.policyOwnerKYC,
      beneficiariesState: (state) => state.quote.beneficiaries,
      healthQuestionaireState: (state) => state.quote.healthQuestionaire,
      goForMedicalsState: (state) => state.quote.goForMedicals,
    }),
  },

  methods: {
    submitInfo() {
      this.healthLoading = true;
      this.$store.commit('quote/healthQuestionaireInfo', this.formAnswer);
      const formData = {
        quoteCode: this.quoteCodeState,
        quoteDeclaration: 'Y',
        quoteGoForMedicals: this.goForMedicalsState,
        quoteSourceFunds: this.quoteSourceFunds,
        client: {
          clientCode: this.clientCodeState,
          clientTitle: this.lifeAssuredKYCState.title,
          clientName: this.lifeAssuredKYCState.surname,
          clientOtherNames: this.lifeAssuredKYCState.othernames,
          clientGender: this.lifeAssuredKYCState.gender,
          clientEmail: this.lifeAssuredKYCState.email,
          clientDateOfBirth: this.lifeAssuredKYCState.dateOfBirth,
          clientTelephone: this.lifeAssuredKYCState.mobileNumber,
          clientMaritalStatus: this.lifeAssuredKYCState.maritalStatus,
          clientOccupation: this.lifeAssuredKYCState.occupation,
          clientEmployer: this.lifeAssuredKYCState.nameOfEmployer,
          clientPhysicalAddress: this.lifeAssuredKYCState.residence,
          clientPIN: this.lifeAssuredKYCState.kraPinNumber,
          clientIDImage: null,
          clientGoodHealth: this.healthQuestionaireState.goodHealth,
          clientGoodHealthComments: this.healthQuestionaireState.goodHealthDetails,
          clientMedCondition: this.healthQuestionaireState.medicalCondition,
          clientMedConditionComments: this.healthQuestionaireState.medicalConditionDetails,
          clientHeight: this.healthQuestionaireState.height,
          clientWeight: this.healthQuestionaireState.weight,
          clientMedCheckup: this.healthQuestionaireState.medicalCondition,
          clientMedCheckupComments: this.healthQuestionaireState.medicalConditionDetails,
          clientType: 'B',
          clientIdNumber: this.lifeAssuredKYCState.idNumber,
          clientNationality: this.lifeAssuredKYCState.nationality,
          clientAdditionalComments: this.healthQuestionaireState.additionalInfoDetails,
        },
        ownerClient: {
          clientCode: null,
          clientName: this.policyOwnerKYCState.surname,
          clientOtherNames: this.policyOwnerKYCState.othernames,
          clientGender: this.policyOwnerKYCState.gender,
          clientEmail: this.policyOwnerKYCState.email,
          clientDateOfBirth: this.policyOwnerKYCState.dateOfBirth,
          clientTelephone: this.policyOwnerKYCState.mobileNumber,
          clientMaritalStatus: this.policyOwnerKYCState.maritalStatus,
          clientOccupation: this.policyOwnerKYCState.occupation,
          clientEmployer: this.policyOwnerKYCState.nameOfEmployer,
          clientPhysicalAddress: this.policyOwnerKYCState.residence,
          clientPIN: this.policyOwnerKYCState.kraPinNumber,
          clientIDImage: null,
          clientGoodHealth: this.healthQuestionaireState.goodHealth,
          clientMedCondition: this.healthQuestionaireState.medicalCondition,
          clientHeight: this.healthQuestionaireState.height,
          clientWeight: this.healthQuestionaireState.weight,
          clientMedCheckup: this.goForMedicalsState ? this.goForMedicalsState : 'N',
          clientType: 'B',
          clientIdNumber: this.policyOwnerKYCState.idNumber,
          clientNationality: this.policyOwnerKYCState.nationality,
        },
        beneficiaries: this.beneficiariesState,
        ailments: [
          {
            clientMedHCode: 0,
            clientMedHClientCode: this.clientCodeState,
            clientMedHAilment: 'Diabetes',
            clientMedHAilmentWef: '2022-05-10T16:35:35.611Z',
            clientMedHAilmentWet: '2022-05-10T16:35:35.611Z',
            clientMedHBodyPart: 'string',
            clientMedHHospital: 'string',
            clientMedHDoctor: 'string',
            clientMedHMedFreq: 'string',
          },
        ],
        familyMember: [
          {
            clientFamHCode: 0,
            clientFamHClientCode: this.clientCodeState,
            clientFamHLifeStatus: 'Alive',
            clientFamHHealthStatus: 'Good',
            clientFamHAge: 20,
            clientFamHSicknessType: 'string',
            clientFamHSicknessDuration: 0,
            clientFamHDeathCause: 'string',
          },
        ],
        clientPhotos: [
          {
            imageType: 'ID-FRONT',
            imageName: this.lifeAssuredKYCImagesState.image1.imageName,
            imageBase64: this.lifeAssuredKYCImagesState.image1.imageBase64,
          },
          {
            imageType: 'ID-BACK',
            imageName: this.lifeAssuredKYCImagesState.image2.imageName,
            imageBase64: this.lifeAssuredKYCImagesState.image2.imageBase64,
          },
          {
            imageType: 'KRA-PIN',
            imageName: this.lifeAssuredKYCImagesState.image3.imageName,
            imageBase64: this.lifeAssuredKYCImagesState.image3.imageBase64,
          },
        ],
      };
      axios
        .post('/lmsagent/quotations/updateFinalQuote', formData)
        .then((res) => {
          const quotationResp = res.data;
          if (quotationResp.lienApplicable === 'Y') {
            this.$emit('to-lien-form');
          } else {
            this.$emit('to-proposal-summary');
          }
          this.$emit('close-lien-dialog');

          this.healthLoading = false;
        })
        .catch(() => {
          this.healthLoading = false;
        });
    },

  },
};
